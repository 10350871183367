<!--
 * @Description: 关于我们页面组件，未完成
 -->
<template>
	<div class="main-box">
        <div class="login">
            <!-- 登录 -->
            <div class="log-box yonghudenglu">
                <div class="tog-log">
                    <div
                        class="log-tit"
                        :class="loginType == 2 ? 'active' : ''"
                        @click="togLoginType(2)"
                    >
                        用户登录
                    </div>
                </div>
                <el-form
                    :model="LoginUser"
                    :rules="rules"
                    ref="ruleForm"
                    class="loginform"
                    label-width="80px"
                >
                    <el-form-item prop="account">
                        <el-input
                            v-model="LoginUser.account"
                            placeholder="请输入邮箱或手机号"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="password" v-show="loginType == 2">
                        <el-input
                            type="password"
                            show-password
                            v-model="LoginUser.password"
                            placeholder="请输入密码"
                        ></el-input>
                    </el-form-item>
                    <el-form-item v-show="loginType == 1">
                        <el-input v-model="LoginUser.code" placeholder="请输入验证码">
                            <template slot="append">
                                <div class="code">获取验证码</div>
                            </template>
                        </el-input>
                    </el-form-item>
                </el-form>
                <!-- <div class="xiyi">
                    <el-checkbox v-model="checked"
                        >请勾选同意
                        <span style="color: #ce3720"
                            >《先谱特电商用户注册协议和隐私政策》</span
                        ></el-checkbox
                    >
                </div> -->
                <div class="btn" @click="Login">登录</div>
            </div>
            <!-- 注册 -->
            <div class="log-box zhuce">
                <div class="tog-log">
                    <div
                        class="log-tit"
                        :class="loginType == 2 ? 'active' : ''"
                        @click="togLoginType(2)"
                    >
                        新用户注册
                    </div>
                </div>
                <el-form
                    :model="RegisterUser"
                    :rules="rules2"
                    ref="ruleFormZc"
                    class="loginform"
                    label-width="80px"
                >
                    <el-form-item prop="username">
                        <el-input
                            v-model="RegisterUser.username"
                            placeholder="请输入邮箱或手机号"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input
                            v-model="RegisterUser.password"
                            type="password" show-password
                            placeholder="请输入密码"
                        ></el-input>
                    </el-form-item>
                    <el-form-item  prop="password2">
                        <el-input
                            v-model="RegisterUser.password2"
                            type="password" show-password
                            placeholder="确认密码"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div class="btn" @click="register">创建一个账户</div>
            </div>
            <!-- 忘记密码 -->
            <div class="log-box wangjimima">
                <div class="tog-log">
                    <div
                        class="log-tit"
                        :class="loginType == 2 ? 'active' : ''"
                        @click="togLoginType(2)"
                    >
                        忘记密码
                    </div>
                </div>
                <div class="wangjimima-tishi">
                    <div>1.输入您的注册邮箱地址。</div>
                    <div>2.点击“提交”。</div>
                    <div>3.我们会发送一封邮件到您的注册邮箱中。</div>
                    <div>4.按照邮件中的指示来重新登录。</div>
                    <div>5.确认已经将@XPTLab.com添加到信任邮件名单中。</div>
                </div>
                <el-form
                    :model="LoginUserZh"
                    :rules="rules3"
                    ref="ruleFormZh"
                    class="loginform"
                    label-width="80px"
                >
                    <el-form-item prop="account">
                        <el-input
                            v-model="LoginUserZh.account"
                            placeholder="请输入邮箱"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div class="btn" @click="LoginZh">提交</div>
            </div>
            <!-- 邮箱登录 -->
            <div class="log-box youxiangdenglu">
                <div class="tog-log">
                    <div
                        class="log-tit"
                        :class="loginType == 2 ? 'active' : ''"
                        @click="togLoginType(2)"
                    >
                        邮箱登录
                    </div>
                </div>
                <div class="youxiangdenglu-tishi">
                    <div>1.无需注册，邮箱登录。</div>
                    <div>2.会有专员通过邮箱和您联系。</div>
                </div>
                <el-form
                    :model="LoginUserYx"
                    :rules="rules4"
                    ref="ruleFormYx"
                    class="loginform"
                    label-width="80px"
                >
                    <el-form-item prop="account">
                        <el-input
                            v-model="LoginUserYx.account"
                            placeholder="请输入邮箱"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div class="btn" @click="LoginYx">提交</div>
            </div>
        </div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import { loginApi,registerApi,changePwdApi } from "../../api/user.js";
export default {
	components: {},
	data() {
		// 用户名的校验方法
		let validateName = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("请输入用户名"));
			}
			this.$refs.ruleForm.validateField("checkPass");
			return callback();
		};
		// 密码的校验方法
		let validatePass = (rule, value, callback) => {
			if (value === "") {
				return callback(new Error("请输入密码"));
			}
			this.$refs.ruleForm.validateField("checkPass");
			return callback();
		};
        // 确认密码的校验方法
		let validateConfirmPass = (rule, value, callback) => {
			if (value === "") {
				return callback(new Error("请输入确认密码"));
			}
			// 校验是否以密码一致
			if (
				this.RegisterUser.password != "" &&
				value === this.RegisterUser.password
			) {
				this.$refs.ruleForm.validateField("checkPass");
				return callback();
			} else {
				return callback(new Error("两次输入的密码不一致"));
			}
		};
		return {
			LoginUser: {
				account: "",
				password: "",
				code: "",
			},
            RegisterUser: {
				username: "",
				password: "",
				password2: "",
			},
            LoginUserZh:{
                account: "",
            },
            LoginUserYx:{
                account: "",
            },
			// 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
			rules: {
				account: [{ validator: validateName, trigger: "blur" }],
				password: [{ validator: validatePass, trigger: "blur" }],
			},
            rules2: {
				username: [{ validator: validateName, trigger: "blur" }],
				password: [{ validator: validatePass, trigger: "blur" }],
				password2: [{ validator: validateConfirmPass, trigger: "blur" }],
			},
            rules3: {
				account: [{ validator: validateName, trigger: "blur" }],
			},
            rules4: {
				account: [{ validator: validateName, trigger: "blur" }],
			},
			checked: false,
			loginType: 2,
		};
	},
	methods: {
		...mapActions(["setUser", "setShowLogin"]),
		Login() {
			// 通过element自定义表单校验规则，校验用户输入的用户信息
			this.$refs["ruleForm"].validate((valid) => {
				//如果通过校验开始登录
				if (valid) {
					loginApi(this.LoginUser)
						.then((res) => {
							// 登录信息存到本地
							let userinfo = JSON.stringify(res.data.userinfo);
							localStorage.setItem("token", res.data.userinfo.token);
							localStorage.setItem("user", userinfo);
							// 登录信息存到vuex
							this.setUser(res.data.userinfo);
							// 弹出通知框提示登录成功信息
							this.$message.success(res.msg);
							this.$router.push("/");
						})
						.catch((err) => {
							// 清空输入框的校验状态
							// this.$refs["ruleForm"].resetFields();
							this.$message.error(err.data.msg);
							return Promise.reject(err);
						});
				} else {
					return false;
				}
			});
		},
		// 跳转注册
		jumpReg() {
			this.$router.push("/user/register");
		},
		togLoginType(type) {
			this.loginType = type;
		},
        register() {
			let data = this.RegisterUser;
			this.$refs["ruleFormZc"].validate((valid) => {
				//如果通过校验开始注册
				if (valid) {
                    registerApi(data)
                        .then((res) => {
                            // 弹出通知框提示注册成功信息
                            this.$message.success(res.msg);
                            this.$router.push("/user/login");
                        })
                        .catch((err) => {
                            this.$message.error(err.data.msg);
                            return Promise.reject(err);
                        });
				} else {
					return false;
				}
			});
		},
        // 找回密码
        LoginZh(){
            // 通过element自定义表单校验规则，校验用户输入的用户信息
			this.$refs["ruleFormZh"].validate((valid) => {
				//如果通过校验开始登录
				if (valid) {
					changePwdApi(this.LoginUserZh)
						.then((res) => {
							console.log(res);
                            this.$message.success(res.msg);
						})
						.catch((err) => {
							// 清空输入框的校验状态
							// this.$refs["ruleForm"].resetFields();
							this.$message.error(err.data.msg);
							return Promise.reject(err);
						});
				} else {
					return false;
				}
			});
        },
        LoginYx(){
            // 通过element自定义表单校验规则，校验用户输入的用户信息
			this.$refs["ruleFormYx"].validate((valid) => {
				//如果通过校验开始登录
				if (valid) {
					loginApi(this.LoginUserYx)
						.then((res) => {
							// 登录信息存到本地
							let userinfo = JSON.stringify(res.data.userinfo);
							localStorage.setItem("token", res.data.userinfo.token);
							localStorage.setItem("user", userinfo);
							// 登录信息存到vuex
							this.setUser(res.data.userinfo);
							// 弹出通知框提示登录成功信息
							this.$message.success(res.msg);
							this.$router.push("/");
						})
						.catch((err) => {
                            console.log(err.data);
                            if (err.data.msg == '密码不正确') {
                                this.$message.error('该邮箱已注册，请直接登录');
                            }else{
                                this.$message.error(err.data.msg);
                            }
							// 清空输入框的校验状态
							// this.$refs["ruleForm"].resetFields();
							return Promise.reject(err);
						});
				} else {
					return false;
				}
			});
		}
    },
};
</script>
<style>
.loginform .el-form-item__content {
	margin-left: 0 !important;

}
.loginform .el-input__inner {
	height: 30px !important;
	font-size: 12px !important;
}
.loginform .el-form-item{
    margin-bottom: 10px;
}
</style>
<style scoped>
    .loginform{
        margin-top: 20px;
    }
    .main-box{
        width: 100%;
        background-color: #F3F3F3;
    }
.code {
	cursor: pointer;
	font-size: 21px;
}
.btn-box-b {
	display: flex;
	justify-content: flex-end;
}
.qita {
	margin-right: 20px;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
}
.login {
    margin: 0 auto;
	padding-top: 100px;
	padding-bottom: 100px;
	width: 1000px;
	/* height: 700px; */
	/* background-image: url("../../assets/index/bglogin.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: top left; */
    
    display: flex;
    flex-wrap: wrap;
}
.zhuce{
    margin-left: 20px;
}
.youxiangdenglu{
    height: 190px;
    margin-left: 20px;
    margin-top: 20px;
}
.wangjimima{
    margin-top: 20px;
}
.tog-log {
	display: flex;
    background-color: #ce3720;
    color: #ffffff;
}
.log-box {
	padding: 10px;
	width: 403px;
	/* height: 379px; */
	background: #ffffff;
    text-align: right;
}
.log-tit {
	position: relative;
	width: 100%;
	height: 30px;
	font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
	font-weight: 500;
	font-size: 14px;
	line-height: 32px;
	text-align: left;
	font-style: normal;
	text-transform: none;
	cursor: pointer;
    /* padding-left: 20px; */
    text-align: center;
}
.yonghudenglu{
    height: 190px;
}
/* .active::after {
	content: "";
	display: block;
	position: absolute;
	bottom: -24px;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	width: 86px;
	height: 8px;
	background: #ce3720;
	border-radius: 3px 3px 3px 3px;
} */
.btn {
    padding-left: 30px;
    padding-right: 30px;
    display: inline-block;
	height: 34px;
	background: #ffffff;
	border-radius: 5px 5px 5px 5px;
	text-align: center;
	line-height: 34px;
	font-weight: 400;
	font-size: 14px;
	color: #333;
	cursor: pointer;
    border: 1px solid #D6D6D6;
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
}
.xiyi {
	font-size: 19px;
	margin: 20px 0;
}
.reg-btn {
	text-align: right;
	color: #ce3720;
	cursor: pointer;
	font-size: 20px;
}
.wangjimima-tishi{
    padding-top: 20px;
    font-size: 12px;
    color: #494949;
    margin-bottom: 10px;
    text-align: left;
}
.youxiangdenglu-tishi{
    padding-top: 20px;
    font-size: 12px;
    color: #494949;
    margin-bottom: 10px;
    text-align: left;
}
</style>
